const shops = {
  shop_at: "Shop at",
  stores: "stores",
  stores_from: "from our Hello Clever app",
  download_hello_clever_app:
    "Download Hello Clever App and get your Instant Cashback now!",
  want_more_deals: "Want more deals?\nDownload our app now",
  got_offer_in_app:
    "We've got <span class='font-semibold text-clever-black'>{{count}}+</span> offers in our app.",
};

export default shops;
