import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import locales from "./languages"

const resources = {
  "en-US": { translation: locales["en-US"] },
}

i18next.use(initReactI18next).init({
  resources,
  lng: "en-US",
  fallbackLng: "en-US",
  interpolation: {
    escapeValue: false,
  },
})

export default i18next
