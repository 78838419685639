const PLATFORM = "Landing";

export const EVENT_CATEGORIES = {
  awarenessAndAcquisition: "AwarenessAndAcquisition",
  awarenessConsideration: "AwarenessConsideration",
  awarenessInterest: "Awareness Interest",
  intent: "Intent",
};

export const TRACKING_EVENTS = {
  // When a user load the Home page
  payToPageLanded: `${PLATFORM}:${EVENT_CATEGORIES.awarenessAndAcquisition}_PayToPageLanded`,

  // When a user load the Home page
  homePageLanded: `${PLATFORM}:${EVENT_CATEGORIES.awarenessAndAcquisition}_HomePageLanded`,

  // When a user load the Merchant page
  merchantPageLanded: `${PLATFORM}:${EVENT_CATEGORIES.awarenessAndAcquisition}_MerchantPageLanded`, // E-commerce

  // When a user load the APIs page
  apisPageLanded: `${PLATFORM}:${EVENT_CATEGORIES.awarenessAndAcquisition}_APIsPageLanded`, // Industries

  // When a user click a download button - Android, at everywhere on the site
  androidDownloadClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessAndAcquisition}_AndroidDownloadClicked`,

  // When a user click a download button - iOS, at everywhere on the site
  iOSDownloadClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessAndAcquisition}_iOSDownloadClicked`,

  // When a session click on a deal
  dealClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_DealClicked`,

  // When a session click on a deal - log offer category
  dealCategoryViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_DealCategoryViewed`,

  // When a session click on [see more deal] button
  seeMoreDealClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_SeeMoreDealClicked`,

  // When a session scroll down to the Benefit section
  consumerBenefitViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ConsumerBenefitViewed`,

  // When a session scroll down to the Referral section
  referralViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ReferralViewed`,

  // When a session visit the blog detail page
  blogViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_BlogViewed`,

  // How many session view more than 2 blogs - Counting the number of blog views
  // blogsViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_BlogsViewed`,

  // When a session click on an industry dropdown tab
  industryViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_IndustryViewed`,

  // When a session click on an industry dropdown tab
  // industriesViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_IndustriesViewed`,

  // When a session click on a service tab: (Buy to earn, PayID, PayTo..)
  serviceViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ServiceViewed`,

  // How many views in total for each service - Counting views for each type of service
  // servicesViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ServicesViewed`,

  // When a session click on a play button or redirect to Case Study detail
  caseStudyViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_CaseStudyViewed`,

  // When a session click on a FAQ - How many session read at least 1 FAQ
  faqViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_FAQViewed`,

  // Counting views for each FAQ - How many session read more than 1 FAQs
  // faqsViewed: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_FAQsViewed`,

  // When a session click on every share icons - How many session share at least 1 article via social media.
  articleSharedClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ArticleSharedClicked`,

  // Counting number of share for each channel - Which channel that they shared the blog post.
  socialChannelSharedClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_SocialChannelSharedClicked`,

  // When the user click subscribe button in the news letter section - How many session subscribe for newsletter
  newsletterSubcribedClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_NewsletterSubcribedClicked`,

  // When the user send a message via Contact page - How many session click on send a message button via contact us
  contactMessageSent: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ContactMessageSent`,

  // When a session click on the [Shop now] button - How many click on [Shop now] button in deal detail
  // shopNowClicked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_ShopNowClicked`,

  // When a session click on the [Book a demo] button - How many click on [Book a demo] button in Merchant pages (Merchant and API Customer)
  // demoBooked: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_DemoBooked`,

  // When a session click on the [Book a demo] button
  usingTypeformData: `${PLATFORM}:${EVENT_CATEGORIES.awarenessConsideration}_UsingTypeformData`,
};
