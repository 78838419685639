import i18Next from "i18next";

import { _toString } from "./Lodash.Util";

export const localesText = (key: string, params?: any) => {
  const text = i18Next.t(key, params);

  if (typeof text !== "string") return _toString(text);

  return text;
};
