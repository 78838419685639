import { NextLink } from "Components/Lib/Next";
import { classnames } from "Utils/Classnames.Util";
import { Typography } from "../../../Typography";

const MenuItem = ({
  menu,
  active,
  target,
}: {
  menu: { id: number; label: string; url: string };
  active: boolean;
  target?: any;
}) => {
  return (
    <li
      suppressHydrationWarning={true}
      className={classnames({
        "text-clever-link font-medium": active,
      })}
    >
      <NextLink
        key={menu.id}
        href={menu.url}
        suppressHydrationWarning={true}
        className={classnames(
          "hover:text-clever-link min-h-[40px] pb-0 pt-0 hover:bg-transparent focus:bg-transparent",
          {
            "disabled-link": active,
          }
        )}
        target={target}
      >
        <Typography.Content className="bg-transparent">
          {menu.label}
        </Typography.Content>
      </NextLink>
    </li>
  );
};

export default MenuItem;
