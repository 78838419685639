import { classnames } from "Utils/Classnames.Util";
import { useSmallMobileScreen } from "Hooks/useMediaQuery";
// small 12
// description 14
// content 16
// body 18
// heading3 20
// heading2 24
// heading1 36
// sectiontitle 48
// giant 60

export interface MyTypographyProps
  extends React.HTMLProps<HTMLParagraphElement> {
  shouldScale?: boolean;
  useDivTag?: boolean;
}

const Small = ({ className, ...rest }: MyTypographyProps) => {
  return (
    <p
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "text-xs leading-relaxed sm:leading-relaxed md:leading-relaxed",
        className,
      )}
    />
  );
};
const Description = ({
  className,
  useDivTag = false,
  ...rest
}: MyTypographyProps) => {
  if (useDivTag) {
    return (
      <div
        {...rest}
        suppressHydrationWarning={true}
        className={classnames(
          "text-xs leading-relaxed sm:text-sm sm:leading-relaxed md:leading-relaxed",
          className,
        )}
      />
    );
  }
  return (
    <p
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "text-xs leading-relaxed sm:text-sm sm:leading-relaxed md:leading-relaxed",
        className,
      )}
    />
  );
};
const Content = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <p
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-relaxed sm:leading-relaxed md:leading-relaxed",
        { "sm:text-base": shouldScale },
        { "text-xs": isSmallDevice && shouldScale },
        { "text-sm": !isSmallDevice },
        { "text-base": !shouldScale },
        className,
      )}
    />
  );
};
const Body = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <p
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-relaxed sm:leading-relaxed md:leading-relaxed",
        { "sm:text-lg": shouldScale },
        { "text-sm": shouldScale && isSmallDevice },
        { "text-base": !isSmallDevice || !shouldScale },
        className,
      )}
    />
  );
};
const Heading3 = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <p
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-relaxed sm:leading-relaxed md:leading-relaxed",
        { "sm:text-xl": shouldScale },
        { "text-base": isSmallDevice && shouldScale },
        { "text-lg": !isSmallDevice },
        { "text-xl": !shouldScale },
        className,
      )}
    />
  );
};

const Heading2 = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <p
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-normal sm:leading-normal md:leading-normal",
        { "sm:text-2xl": shouldScale },
        { "text-lg": isSmallDevice && shouldScale },
        { "text-xl": !isSmallDevice },
        { "text-2xl": !shouldScale },
        className,
      )}
    />
  );
};

const Heading1 = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <h3
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-normal sm:leading-normal md:leading-normal",
        { "sm:text-3xl md:text-4xl": shouldScale },
        { "text-xl": isSmallDevice && shouldScale },
        { "text-2xl": !isSmallDevice },
        { "text-4xl": !shouldScale },
        className,
      )}
    />
  );
};

const SectionTitle = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <h2
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-normal sm:leading-normal md:leading-normal",
        { "sm:text-[42px] md:text-[47px]": shouldScale },
        { "text-3xl": isSmallDevice && shouldScale },
        { "text-3xl": !isSmallDevice },
        { "text-[50px]": !shouldScale },
        className,
      )}
    />
  );
};
const Giant = ({
  shouldScale = true,
  className,
  ...rest
}: MyTypographyProps) => {
  const isSmallDevice = useSmallMobileScreen();
  return (
    <h1
      {...rest}
      suppressHydrationWarning={true}
      className={classnames(
        "leading-snug sm:leading-snug md:leading-snug",
        { "sm:text-[50px] md:text-[60px]": shouldScale },
        { "text-4xl": isSmallDevice && shouldScale },
        { "text-4xl": !isSmallDevice },
        { "text-[60px]": !shouldScale },
        className,
      )}
    />
  );
};

const Typography = {
  Small,
  Description,
  Content,
  Body,
  Heading3,
  Heading2,
  Heading1,
  SectionTitle,
  Giant,
};

export { Typography };
