/* eslint-disable max-len */
import { useMemo, useState, memo } from "react";
import Image, { ImageProps } from "next/image";

import {
  useSmallMobileScreen,
  useMobileScreen,
  useTabletScreen,
} from "Hooks/useMediaQuery";
import { _isNumber } from "Utils/Lodash.Util";
import { formatToNumber } from "Utils/Number.Util";

import LoadFailedImage from "Assets/Images/Others/image_failed_default.png";

const myLoader = (props: any) => {
  return `${props.src}?w=${props.width}&q=${props.quality || 75}`;
};

const NextImage: React.FC<
  ImageProps & {
    scaleForSmallDevice?: boolean;
    scaleSize?: number;
    smallDeviceScaleSize?: number;
    tabletScaleSize?: number;
    loadFailedImageUrl?: any;
  }
> = ({
  loadFailedImageUrl = LoadFailedImage,
  scaleForSmallDevice = true,
  scaleSize = 0.85,
  smallDeviceScaleSize = 0.75,
  tabletScaleSize = 0.9,
  src,
  loader = myLoader,
  width,
  height,
  alt = "",
  ...rest
}) => {
  const [state, setState] = useState({ src: src || loadFailedImageUrl });

  const onLoadError = () => {
    setState({ src: loadFailedImageUrl });
  };

  const isSmallDevice = useSmallMobileScreen();
  const isMobile = useMobileScreen();
  const isTablet = useTabletScreen();

  const scaleWidth = useMemo(() => {
    if (scaleForSmallDevice && _isNumber(width)) {
      const scaled = scaleSize * width;
      if (isSmallDevice) return smallDeviceScaleSize * scaled;
      if (isMobile) return scaled;
      if (isTablet) return tabletScaleSize * width;
    }

    return width;
  }, [
    isMobile,
    isSmallDevice,
    isTablet,
    scaleForSmallDevice,
    scaleSize,
    smallDeviceScaleSize,
    tabletScaleSize,
    width,
  ]);

  const scaleHeight = useMemo(() => {
    if (scaleForSmallDevice && _isNumber(height)) {
      const scaled = scaleSize * height;
      if (isSmallDevice) return smallDeviceScaleSize * scaled;
      if (isMobile) return scaled;
      if (isTablet) return tabletScaleSize * height;
    }

    return height;
  }, [
    scaleForSmallDevice,
    height,
    scaleSize,
    isSmallDevice,
    smallDeviceScaleSize,
    isMobile,
    isTablet,
    tabletScaleSize,
  ]);

  const DEFAULT_SIZES =
    "(max-width: 768px) 100vw, (max-width: 1024px) 75vw, (max-width: 1280px) 50vw, 30vw";

  return (
    <Image
      src={state.src}
      onError={onLoadError}
      alt={alt}
      width={scaleWidth}
      height={scaleHeight}
      placeholder={
        (!!width && formatToNumber(width) < 40) ||
        (!!height && formatToNumber(height) < 40)
          ? "empty"
          : "blur"
      }
      blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      sizes={_isNumber(width) && width <= 100 ? `${width}px` : DEFAULT_SIZES}
      quality={_isNumber(width) && width >= 1800 ? 65 : 75}
      {...rest}
      suppressHydrationWarning={true}
    />
  );
};

export default memo(NextImage);
