import dynamic from "next/dynamic";

import { HEADER_HEIGHT, PAGE_THEME } from "Constants/Common.Constant";
import { NextLink } from "Components/Lib/Next";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { classnames } from "Utils/Classnames.Util";
import { useThemeContext } from "Context/Theme.Context";
import { useMediaQuery } from "Hooks/useMediaQuery";

const LayoutHeader = dynamic(() => import("./Header.Container"), {
  loading: () => <SuspendHeader />,
  ssr: false,
});

const SuspendHeader = () => {
  const { theme } = useThemeContext();

  const isSmallTabletOrMobile = useMediaQuery({ maxWidth: 960 });

  return (
    <header
      style={{
        height: isSmallTabletOrMobile ? 0.7 * HEADER_HEIGHT : HEADER_HEIGHT,
      }}
      className={classnames(
        "flex justify-center px-[4%] py-4 sm:px-[3%] md:px-[2%]",
        {
          "bg-clever-primary": theme === PAGE_THEME.PRIMARY,
          "bg-clever-secondary": theme === PAGE_THEME.SECONDARY,
          "bg-clever-purple": theme === PAGE_THEME.PURPLE,
        },
      )}
    >
      <div className="flex w-full items-center justify-between gap-5">
        <NextLink href={PATHS_NAME.HOME}>
          <div
            className={classnames({
              "small-logo-container": isSmallTabletOrMobile,
              "big-logo-container": !isSmallTabletOrMobile,
            })}
          >
            <div
              className={classnames({
                "logo-image": theme === PAGE_THEME.PRIMARY,
                "black-logo-image":
                  theme === PAGE_THEME.SECONDARY || theme === PAGE_THEME.PURPLE,
              })}
            />
          </div>
        </NextLink>
      </div>
    </header>
  );
};

export default LayoutHeader;
