const qr_code = {
  QR_code: "QR Code",
  instructions: "Instructions",
  step_1: "Copy the PayID.",
  step_2: "Go to your online bank or mobile banking app.",
  step_3: "Select the option to pay to an email address and paste the PayID.",
  copy: "Copy PayID",
};

export default qr_code;
