import { event } from "nextjs-google-analytics";

interface IHandleAnalyticEvent {
  key: string;
  params?: { [key: string]: any };
}

export const logAnalyticEvent = async ({
  key,
  params,
}: IHandleAnalyticEvent) => {
  if (typeof window === "undefined") return;

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "PRODUCTION") {
    event(key, params);
  }
};
