import dynamic from "next/dynamic";

const LayoutFooter = dynamic(() => import("./Footer.Container"), {
  loading: () => <SuspendFooter />,
  ssr: false,
});

const SuspendFooter = () => {
  return (
    <footer
      className="flex justify-center bg-dark px-[4%] pb-20 pt-24 sm:px-[3%] md:px-[2%]"
      style={{ minHeight: 560 }}
    />
  );
};

export default LayoutFooter;
