import { HOW_WORKS_TYPE, PAYMENT_METHODS_TYPE } from "Types";

import { PATHS_NAME } from "./PathName.Constant";

export const STATUS_CODES = {
  SERVER_NOT_RESPONSE: "ECONNABORTED",
  INTERNAL_SERVER_ERROR: 500,
  NO_INTERNET: 0,
  TOO_MANY_REQUESTS: 429,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const KEYS = {
  TOKEN: "auth_token",
};

export const PAGE_THEME = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  PURPLE: "purple",
};

export const SUPPORT_EMAIL = "support@helloclever.co";
export const BUSINESS_EMAIL = "partnerships@helloclever.co";

export const DEFAULT_SEO_TITLE =
  "Hello Clever | Shop with brands you love, earn instant cashback with Hello Clever";

export const DEFAULT_SEO_DESCRIPTION =
  // eslint-disable-next-line max-len
  "The first Australian buy-to-earn platform that makes shopping, payments, and money management easier for all. Trusted by 100,000+ Australians and 1,000+ merchants. Join us now!";

export const MAX_CONTENT_WIDTH = 1440;
export const MAX_INNER_CONTENT_WIDTH = 1360;
export const MAX_FOOTER_WIDTH = 1100;

export const ALERT_BANNER_HEIGHT = 50; // px
export const DEFAULT_HEADER_HEIGHT = 115;
export const HEADER_HEIGHT = 115 + ALERT_BANNER_HEIGHT; // px

export const PAYMENT_METHODS = {
  BUY_TO_EARN: "buy_to_earn" as PAYMENT_METHODS_TYPE,
  PAY_ID: "pay_id" as PAYMENT_METHODS_TYPE,
  PAY_TO: "pay_to" as PAYMENT_METHODS_TYPE,
  CARD: "card" as PAYMENT_METHODS_TYPE,
};

export const HOW_WORKS = {
  STEP_ONE: "step_one" as HOW_WORKS_TYPE,
  STEP_TWO: "step_two" as HOW_WORKS_TYPE,
  STEP_THREE: "step_three" as HOW_WORKS_TYPE,
};

export const REVALIDATE_TIME = 60 * 15; // 15 minutes

export const METADATA_TITLE = {
  home: "Hello Clever | Real-Time Payments & Instant Cashback Rewards",
  about: "Hello Clever | About",
  getInTouch: "Hello Clever | Contact",
  policy: "Hello Clever | Privacy Policy",
  terms: "Hello Clever | Terms and Conditions",

  blogs: "Hello Clever | Blogs",
  blog: "Hello Clever | ",
  caseStudies: "Hello Clever | Case Studies",
  caseStudy: "Hello Clever | ",
  shops: "Hello Clever | Shops",
  shop: "Hello Clever | ",

  eCommerce:
    "Hello Clever | PayID, PayTo & Instant Cashback Solution for E-commerce",
  industries: "Hello Clever | Real-time Payments for Billers",

  selectBank: "Hello Clever | Banks",
  qrCode: "Hello Clever | Qr Code",
  salesReport: "Hello Clever | Sales Report",
  payto:
    "Hello Clever | PayTo - Real-Time Recurring Payments with Instant Cashback",
  status: "Hello Clever | Status",
  xero: "Hello Clever | PayID and PayTo for Xero",

  tyroConsumer: "Hello Clever | PayID Consumer",
  inStore: "Future of Rewards | Instant Cashback by Hello Clever",
  tyro: "Future of Rewards | Instant Cashback by Hello Clever",
  "500": "Hello Clever | 500",
  "404": "Hello Clever | 404",
};
export const METADATA_DESCRIPTION = {
  home: "Powering PayID and PayTo real-time payments for e-commerce, billers, and businesses, with instant cashback rewards for their customers",
  payto:
    "Our low-cost, simple PayTo solution allows you to collect recurring and one-off payments directly from customers' bank accounts in real-time.",
  xero: "Get your invoices paid in real-time and improve your cashflow.",
  inStore:
    "Drive new customers in-store and reward regulars with Hello Clever's Instant POS Cashback solution. First 3 months free. Sign up today!",
  tyro: "Drive new customers in-store and reward regulars with Hello Clever's Instant POS Cashback solution. First 3 months free. Sign up today!",
  eCommerce:
    "Receive funds instantly with real-time customer payments. A low-cost plugin for WooCommerce, Shopify, Magento, OpenCart, or custom builds.",
  industries:
    "Bring your outdated BPAY and Direct Debit payment options into the future with Hello Clever PayTo and PayID for Business.",
};

export const CASE_STUDY_TAG = "Case Study";
export const ALL_CATEGORIES_TAG = "All Categories";
export const BLOG_CATEGORY = {
  ALL_CATEGORIES: {
    name: "All Categories",
    slug: "all_categories",
  },
  LEARN: {
    name: "Learn",
    slug: "learn",
  },
  NEWS: {
    name: "News",
    slug: "news",
  },
  PEOPLE: {
    name: "People",
    slug: "people",
  },
  PRODUCT: {
    name: "Product",
    slug: "product",
  },
  CASE_STUDY: {
    name: "Case Study",
    slug: "case-study",
  },
};

export const CASE_STUDY_TITLE = "Case Study";
export const CASE_STUDY_SLUG =
  "case-study-hello-clever-has-partnered-with-award-winning-telco-launtel-to-deliver-real-time-payments-to-their-customers-around-australia";

export const BIG_FOUR_BANKS_IDS = [1, 2, 4, 5];

export const NAME_MAX_LENGTH = 200;
export const MESSAGE_MAX_LENGTH = 1000;

export const DATE_TIME_FORMAT_FE = "MMM DD, YYYY";

export const BLOGS_CONTANT = {
  EMBEDDED: "_embedded",
  MEDIA: "wp:featuredmedia",
  TERM: "wp:term",
};

export const SHOW_ALERT_URLS = [PATHS_NAME.HOME];
