const errors = {
  oops_something_went_wrong: "Oops! Something Went Wrong",
  token_expired: "Token expired!",
  too_many_request: "Too many requests, please try again later.",
  try_to_refresh:
    "Try to refresh this page or feel free to contact us if the problem persists.",
  oops_nothing_was_found: "Oops! Nothing was found",
  temporarily_unavailable:
    "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
  field_required: "This field is required",
  max_exceed: "Cannot exceed {{maxCount}} characters",
  email_invalid: "Your email is invalid.",
};

export default errors;
