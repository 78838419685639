import { memo } from "react";
import { useRouter } from "next/router";

import { MENUS } from "Constants/Menu.Constant";
import { classnames } from "Utils/Classnames.Util";
import { NextLink } from "Components/Lib/Next";
import { localesText } from "Utils/LocalesText.Util";
import { DEMO_FORM, MERCHANT_SIGN_UP } from "Constants/URL.Constant";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { TRACKING_EVENTS } from "Constants/Event.Constant";

import { Typography } from "../../../Typography";
import AppDownloadButton from "../../../AppDownloadButton";
import Button from "../../../Button";

import BussinessCollapse from "./BussinessCollapse";
import MenuItem from "./MenuItem";
import { PATHS_NAME } from "Constants/PathName.Constant";

const DrawerContent = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const router = useRouter();

  return (
    <div className="flex h-full w-full flex-col pb-2">
      <div className="flex justify-start">
        <button
          onClick={(event) => {
            event.preventDefault();
            closeDrawer();
          }}
          className="p-5"
        >
          <img
            src="/Assets/Svg/close-icon.svg"
            width={12}
            height={12}
            alt="close-icon"
          />
        </button>
      </div>

      <div className="flex-1 overflow-y-auto pb-32">
        <div className="flex justify-center gap-4 py-3">
          <NextLink href={MERCHANT_SIGN_UP} target="_blank">
            <Button
              buttonSize="small"
              className="w-[100px] flex-1 rounded-3xl bg-black"
            >
              <Typography.Description>
                {localesText("common.sign_up")}
              </Typography.Description>
            </Button>
          </NextLink>

          <NextLink href={MERCHANT_SIGN_UP} target="_blank">
            <Button
              buttonSize="small"
              className="w-[100px] flex-1 rounded-3xl bg-clever-primary"
            >
              <Typography.Description>
                {localesText("common.log_in")}
              </Typography.Description>
            </Button>
          </NextLink>
        </div>
        <div className="flex flex-1 flex-col gap-y-7 p-2">
          <ul className="menu">
            {MENUS.map((menu, index) => {
              if (menu.hasSubmenu) {
                return (
                  <BussinessCollapse
                    key={menu.id}
                    menu={menu}
                    index={index}
                    currentPath={router.asPath}
                  />
                );
              } else {
                return (
                  <MenuItem
                    key={menu.id}
                    menu={menu}
                    active={router.asPath === menu.url}
                  />
                );
              }
            })}
          </ul>
        </div>
        <div className="mt-2 flex flex-wrap justify-center gap-4">
          <AppDownloadButton theme="dark" showSeperate />
          <NextLink
            href={PATHS_NAME.GET_IN_TOUCH}
            onClick={() => {
              logAnalyticEvent({ key: TRACKING_EVENTS.usingTypeformData });
            }}
          >
            <Button
              className={classnames(
                "h-12 w-[170px] rounded-full border-none bg-clever-green text-clever-black"
              )}
            >
              <Typography.Content shouldScale={false}>
                {localesText("common.say_hello")}
              </Typography.Content>
            </Button>
          </NextLink>
        </div>
      </div>
    </div>
  );
};

export default memo(DrawerContent);
