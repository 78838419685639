const xero = {
  xero: "Xero",
  receive_real_time: "Receive ",
  payments_in: "\ninvoice payments\nin real time",
  faqs: "FAQs",
  optimse_cashflow: "Optimise your cashflow",
  receive_customer:
    "Receive customer payments in seconds, not days. With our Xero integration, you get paid faster and boost your cash flow.",
  eliminate: "Eliminate manual processes",
  streamline:
    "Streamline your payment reconciliation process and eliminate administrative burden, costs, and errors.",
  offer: "Offer instant cashback rewards ",
  get_paid_real_time:
    "Get paid in real-time, reward in real-time. Drive customer loyalty and timely payments with instant cash incentives, redeemable at the time of payment.",
  get_paid: "Get paid in",
  real_time: "real-time",
  real_time_text:
    "Say goodbye to waiting for funds to settle over multiple business days. With Hello Clever, when customers pay your invoice, you'll receive funds instantly, instead of days later. Unlock faster access to your money and thrive in a real-time economy.",
  reward_customers: "Reward customers",
  with: "with",
  cashback: "cashback",
  cashback_text_one:
    "Incentivise on-time payments, boost customer loyalty, and drive adoption of real-time payments with instant cashback rewards.",
  cashback_text_two:
    "Customers earn their cashback on the spot, right when they pay. You have control over which invoices will receive cashback, and how much.",
  reduce_fraud: "Reduce fraud",
  risk_and_disputes: "risk and disputes",
  reduce_fraud_text_one:
    "Invoice payments are processed instantly from your customer's bank account. This mitigates fraud and errors, while a Confirmation of Payee step adds an extra layer of security.",
  reduce_fraud_text_two:
    "Real-time payments also eliminate chargebacks, reducing possible disputes and fees for a more reliable payment solution.",
  recurring: "Recurring",
  bank_payments: "bank payments",
  bank_payments_text_one:
    "With PayTo, easily establish payment agreements with your customers for fast, cost-effective transactions when issuing invoices.",
  bank_payments_text_two:
    "Once a customer approves a single payment authorisation, securely initiate bank payments directly from their accounts for recurring or ad-hoc payments. A faster, and lower-cost alternative to card payments.",
  find_us: "Find us in Xero",
  find_us_text:
    "Set up is fast and easy. Simply add Hello Clever from the Xero app store and you’re ready to start creating invoices with real-time payment options.",
  app_store: "app store",
  simple: "Simple, ",
  invoicing: "invoicing",
  fast: "fast",
  simple_invoicing_text:
    "Enjoy real-time notifications, secure fast settlements, and a more rewarding customer experience, all in one solution.",
  //slider
  clear: "clear",
  simple_pricing: " simple pricing",
  simple_pricing_straightforward:
    "Straightforward, transparent fee structure, with just a low per-transaction charge. No hidden costs, no surprises.",
  simple_pricing_transaction:
    "1% of invoice value, capped at $3.00 ex GST per paid invoice.",
  how_does: "How does Hello Clever integrate with Xero?",
  faq_text_one:
    "Hello Clever is a simple plug in that you can add from the Xero app marketplace. Then set up takes just minutes. Create your account and your ready to accept real-time payments.",
  what_payment: "What payment methods can my customers pay me with?",
  faq_text_two:
    "Customers can pay you using PayID, PayTo or debit / credit cards.",
  what_is_npp: "What is the NPP ?",
  faq_text_three:
    "The New Payments Platform (NPP) is a fast payment infrastructure in Australia that enables simple addressed payments with near real-time funds availability, 24/7.",
  what_is_payId: "What is PayID and PayTo?",
  payid_text_one:
    "PayID is a unique identifier linked to a bank account, such as a phone number or email address, that enables real-time payments for customers via the NPP.",
  payid_text_two:
    "PayTo combines the benefits of a direct debit-style system and a physical card, allowing for both one-off and recurring payments from customers' bank account, with real-time settlement.",
  when_will_receive: "When will I receive payment from my customer?",
  faq_text_five:
    "When a customer chooses PayID or PayTo as their payment method, you’ll receive their funds in seconds, not next day or after the weekend. In real-time.",
  how: "How",
  it_works: " it works",
  step_one: "step 1",
  step_two: "step 2",
  step_three: "step 3",
  it_work_text_one: "Connect Hello Clever to your Xero Account.",
  it_work_text_two:
    "When creating an invoice in Xero, the Hello Clever payment option will be displayed directly on the invoice. This allows customers to pay using PayID, PayTo, or debit/credit card options.",
  it_work_text_three:
    "When customer uses PayID or PayTo, you’ll receive payment in real-time. Plus have the added option to offer your customer instant cashback.",
  learn_more_here: " Learn more here",
  what_clients_say: "What our clients say",
  about: "about",
  for_xero: "for Xero",
  what_clients_say_text_one:
    "Hello Clever is straightforward and seamlessly integrates with my accounting software in just a few minutes. The real-time payment feature is a game-changer, eliminating the wait time for funds to land in my account.",
  what_clients_say_text_two:
    "It also accepts both credit cards and PayID payments, which my clients love! Hello Clever also sends detailed emails notifying me of paid invoices and automates reconciliation, making bank reconciliations a breeze.",
};

export default xero;
