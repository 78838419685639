const policy = {
  privacy_policy: "Privacy Policy",

  a_message: "A message from the Clever team",
  a_message_one:
    "Hello Clever Pty Ltd ACN 637 950 497 (“Hello Clever,” “our,” “us,” or “we”) respects your right to privacy and are committed to safeguarding the privacy of our customers and Hello Clever Platform users. We adhere to the Australian Privacy Principles established under the Privacy Act 1988 (Cth).",
  a_message_two:
    "This policy sets out how we collect and treat your personal information. By accessing or using the Hello Clever Platform, you accept and agree to the practices this Privacy Policy describes.",
  a_message_three:
    "If you do not agree to any of the terms in this Privacy Policy, you should not use the Hello Clever Platform.",
  a_message_four:
    "This Privacy Policy was last updated on 25 March, 2024.",

  we_respect: "1. Changes to This Privacy Policy",
  we_respect_one:
    "We may update this Privacy Policy from time to time, including to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will post the revised Privacy Policy on the Hello Clever Platform, update the “last updated” date, and take any other steps required by applicable law.",
  we_respect_two:
    "We encourage users to frequently check this page for any changes to stay informed about we handle personal information. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
  we_respect_three:
    "Your continued use of the Hello Clever Platform following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.",

  personal_information:
    "2.	Scope of This Privacy Policy",
  personal_information_one:
    "This Privacy Policy covers how we process the Personal Information of our existing and perspective customers, end-users who use or request to use the Hello Clever Platform, visitors of our websites, applications, merchants, vendors, partners and those who participate in our promotions or events (“you” or “your”). It also describes your data protection rights, including your right to object to some of the processing activities we carry out.",
  personal_information_two:
    "This Privacy Policy applies to all Personal Information that we collect, use, or disclose when providing our websites, platforms, apps, products, and services owned or operated by us, including in relation to the following:",
  personal_information_two_list_one:
    "our website helloclever.co, and sub-domains (the “Site”)",
  personal_information_two_list_two:
    "our mobile application (the “Hello Clever App”)",
  personal_information_two_list_three:
    "our cloud-based software-as-a-service solution enabling instant cashback capabilities (“Hello Clever Instant Cashback”) and ",
  personal_information_two_list_four:
    "our related services, ",
  personal_information_two_list_five:
    "(together, the “Hello Clever Platform”)",
  personal_information_three: "Information We Collect through Cookies",
  personal_information_four:
    "We also automatically collect certain information about your interaction with the Services (“Usage Data”). To do this, we may use cookies, pixels, and similar technologies (“Cookies”). Usage Data may include information about how you access and use our Site and your account, including device information, browser information, information about your network connection, your IP address and other information regarding your interaction with the Services.",
  personal_information_five: "Information We Obtain from Third Parties",
  personal_information_six:
    "We may obtain information about you from third parties, including from vendors and service providers who may collect information on our behalf, such as:",
  personal_information_six_list_one:
    "Companies who support our Site and Services, such as Shopify.",
  personal_information_six_list_two:
    "Our payment processors, who collect payment information (e.g., bank account, credit or debit card information, billing address) to process your payment in order to fulfill your orders and provide you with products or services you have requested, in order to perform our contract with you.",
  personal_information_six_list_three:
    "When you visit our Site, open or click on emails we send you, or interact with our Services or advertisements, we, or third parties we work with, may automatically collect certain information using online tracking technologies such as pixels, web beacons, software developer kits, third-party libraries, and cookies.",
  personal_information_seven:
    "Any information we obtain from third parties will be treated in accordance with this Privacy Policy. We are not responsible or liable for the accuracy of the information provided to us by third parties and are not responsible for any third party's policies or practices. For more information, see the section below, Third Party Websites and Links.",

  how_use_personal_data: "3. What is Personal Information?",
  how_use_personal_data_one:
    "Personal information refers to any information relating to an identified or identifiable natural person, i.e. one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person (“Personal Information”).",
  how_use_personal_data_two:
    "We do not collect information regarding your race, ethnicity, religious or philosophical beliefs, political beliefs, sexual orientation, genetic information, or information about your health.",
  how_use_personal_data_three:
    "We use your personal information to detect, investigate or take action regarding possible fraudulent, illegal, or malicious activity. If you choose to use the Services and register an account, you are responsible for keeping your account credentials safe. We highly recommend that you do not share your username, password, or other access details with anyone else. If you believe your account has been compromised, please contact us immediately.",
  how_use_personal_data_four:
    "We do not collect information regarding your race, ethnicity, religious or philosophical beliefs, political beliefs, sexual orientation, genetic information, or information about your health.",

  how_share_personal_data: "4. How We Collect Personal Information",
  how_share_personal_data_one:
    "When you use the Hello Clever Platform, whether directly or through a third party website or mobile application, we may collect, store, and process various kinds of data, including Personal Information.",
  how_share_personal_data_two:
    "The Personal Information that we may collect about you broadly falls into the following categories:",
  how_share_personal_data_two_1:
    "Information You Provide to us Directly",
  how_share_personal_data_two_2:
    "We collect the information you provide to us when you do things such as request, sign up for or use the Hello Clever Platform, register or update your account, respond to a job application or an email offer, participate in community forums, join us on social media, take part in competitions or events, contact us with questions or request support, or voluntarily interact with us in other ways.",
  how_share_personal_data_two_3:
    "This may include:",
    how_share_personal_data_two_list_one:
    "Basic contact details including your name, address, phone number, email.",
  how_share_personal_data_two_list_two:
    "Order information including your name, billing address, shipping address, payment confirmation, email address, phone number.",
  how_share_personal_data_two_list_three:
    "Account information including your username, password, security questions.",
  how_share_personal_data_two_list_four:
    "Shopping information including the items put in your cart, or add to your wish list.",
  how_share_personal_data_two_list_five:
    "Information from our interactions (such as when you use the Hello Clever Platform) including when you communicate with us via email, telephone, SMS, video conference, social media or chatbots.",
  how_share_personal_data_two_list_six:
    "Customer support information including the information you choose to include in communications with us, for example, when sending a message through the Hello Clever Platform.",
  
  how_share_personal_three:
    "Information We Collect Automatically",
  how_share_personal_three_1:
    "We automatically collect usage information when you browse or use the Hello Clever Platform to improve our services and enhance your user experience (for example, by personalising the content you see). To do this, we may use cookies, pixels and similar technologies (“Cookies”).",
  how_share_personal_three_2:
    "This may include information about how you use our digital properties (including the Hello Clever Platform, third-party websites, social media sites, apps and electronic communications) such as, what pages you looked at, what items you viewed and what links you clicked on, device information, browser information, information about your network connection, your IP address, marketing and cookie preferences, including any consents you have given us. ",

  how_share_personal_four:
    "Information We Obtain from Third Parties",
  how_share_personal_four_1:
    "We may obtain information about you from third parties, including from vendors and service providers who may collect information on our behalf, such as:",
  how_share_personal_four_list_one: "Companies who support the Hello Clever Platform (e.g. Shopify, WooCommerce and other platforms) ",
  how_share_personal_four_list_two: "Our payment processors, who collect payment information (e.g., bank account, credit or debit card information, billing address) to process your payment in order to fulfill your orders and provide you with products or services you have requested, in order to perform our contract with you.",
  how_share_personal_four_list_three: "When you visit the Hello Clever Platform, open or click on emails we send you, or interact with the Hello Clever Platform or advertisements, we, or third parties we work with, may automatically collect certain information using online tracking technologies such as pixels, web beacons, software developer kits, third-party libraries, and cookies.",
  how_share_personal_four_2: "Any information we obtain from third parties will be treated in accordance with this Privacy Policy. We are not responsible or liable for the accuracy of the information provided to us by third parties and are not responsible for any third party's policies or practices. For more information, see the section below, Third Party Websites and Links.",

  our_legal: "5. How and Why We Collect Personal Information",
  our_legal_one:
    "We must have a legal basis to process your Personal Information and we explain these bases below. We also explain the purposes for which we process your Personal Information, the processing operations we carry out, and the categories of information we use for each purpose. ",
  our_legal_two: "Where we collect Personal Information, we’ll only process it in the following circumstances:",
  our_legal_two_list_one_highlight: "To perform our obligations under a contract with you.",
  our_legal_two_list_one: "We have obligations under our contract to you and to fulfil those obligations, we will have to use your Personal Information. If we don’t collect your Personal Information, we may be unable to provide you with all our services, and some Hello Clever functions and features may not be available to you.",
  our_legal_two_list_two_highlight: "Where we have your consent.",
  our_legal_two_list_two: "In certain cases, we ask for your consent to use your Personal Information. If when we need to ask for your consent, we will explain the situations where we use your Personal information and the purposes for which your Personal Information will be used. ",
  our_legal_two_list_three_highlight: "In accordance with a legal obligation.",
  our_legal_two_list_three: "We are required to comply with relevant legal and regulatory obligations. If we ask you to provide Personal Information to comply with a legal requirement, we will make this clear at the relevant time and advise you whether the provision of your Personal Information is mandatory or not (as well as the possible consequences if you do not provide your Personal Information). ",
  our_legal_two_list_four_highlight: "Where we have legitimate interests.",
  our_legal_two_list_four: "We can process your Personal Information when this is necessary for us (or a third party) to achieve a business purpose, so long as they’re in accordance with your rights.",
  our_legal_three: "If we collect and use your Personal Information in reliance on our legitimate interests (or those of any third party), this interest will normally be to provide and improve the Hello Clever Platform, respond to your inquiries, and support requests, and communicate with you about our products and services, and for any other purpose with your consent. We may have other legitimate interests, and if appropriate, we will make clear to you at the relevant time what those legitimate interests are. ",
  our_legal_four: "If you have questions about or need further information concerning the legal basis on which we collect and use your Personal Information, please contact us using the contact details provided under the “Contact Us” heading below. ",

  share_personal: "6. How We Share Your Personal Information",
  share_personal_one:
    "Other than disclosure to service providers and merchants (explained below) or as required by law, we do not give Personal Information to third parties unless we have disclosed the use in this Privacy Policy, or you have expressly consented for us to do so.",
  share_personal_one_1: "We may disclose your Personal Information as follows:",
  share_personal_two:
    "With affiliates, contractors, service providers, and other third parties we use to support our business on a need-to-know basis to allow the provision of the Hello Clever Platform to you or as requested by you;",
  share_personal_three:
    "To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganisation, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us about the Hello Clever Platform users is among the assets transferred;",
  share_personal_four:
    "To enforce any agreement, including any applicable terms of service;",
  share_personal_five:
    "To establish or exercise our right to defend against legal claims;",
  share_personal_six:
    "To law enforcement and other government authorities such as legislatures, courts, agencies and litigants if we reasonably believe that such action is necessary to: (a) comply with the law and the reasonable requests of governmental authorities; (b) comply with legal process; (c) respond to requests from public or government authorities, including public or government authorities outside your country of residence; (d) protect the security or integrity of the Services’ information systems; and/or (e) exercise or protect our rights, privacy, safety or those of affiliates, clients, you or others; and",
  share_personal_seven:
    "If we reasonably believe disclosure is necessary or appropriate to protect the rights, property, or safety of Hello Clever or others.",
  share_personal_eight: "We may also share Personal Information with merchants for marketing purposes, including via Google Analytics or any other tools. Access to and use of Personal Information by merchants is governed by their own privacy policies and all applicable law. Merchants are required to provide you with access to their terms and privacy policies.",

  third_party: "7. Third Party Websites and Links",
  third_party_one:
    "The Hello Clever Platform may provide links to websites or other online platforms operated by third parties. If you follow links to sites not affiliated or controlled by us, you should review their privacy and security policies and other terms and conditions. We do not guarantee and are not responsible for the privacy or security of such sites, including the accuracy, completeness, or reliability of information found on these sites. Information you provide on public or semi-public venues, including information you share on third-party social networking platforms may also be viewable by other users of the Hello Clever Platform and/or users of those third-party platforms without limitation as to its use by us or by a third party. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators, except as disclosed on the Hello Clever Platform.",

  marketing_data: "8. Marketing Communication and Preferences (Consent)",
  marketing_data_one:
    "In some cases, we may send you direct marketing based on our legitimate interests or where you have provided us with explicit consent. These communications may be sent in various forms, including mail, social media, SMS, or email.",
  marketing_data_two:
    "You have an absolute right to opt out of direct marketing at any time. You can do this by following the instructions in the communication within the electronic message we send to you, or by contacting us via email at ",
  marketing_data_three:
    "We will never share your mobile telephone number or SMS opt-in consent status with these third parties to use for their own marketing purposes without your explicit consent.",
  marketing_data_four:
    "We may still send you important notices relating to your account, operational activities, and technical updates, even after you have opted out of receiving marketing communications.",

  cookie_one: "9. Cookies",
  cookie_two:
    "Like many websites, we use Cookies on the Hello Clever Platform. We use Cookies to power and improve the Hello Clever Platform (including to remember your actions and preferences), to run analytics and better understand user interaction with the Hello Clever Platform (in our legitimate interests to administer, improve and optimise the Hello Clever Platform). We may also permit third parties and services providers to use Cookies on the Hello Clever Platform to better tailor the services, products and advertising on the Hello Clever Platform.",
  cookie_three:
    "Most browsers automatically accept Cookies by default, but you can choose to set your browser to remove or reject Cookies through your browser controls. Please keep in mind that removing or blocking Cookies can negatively impact your user experience and may cause some of the Hello Clever Platform, including certain features and general functionality, to work incorrectly or no longer be available. Additionally, blocking Cookies may not completely prevent how we share information with third parties such as our advertising partners.",

  your_key_rights: "10.	Your Data Rights and Choices.",
  your_key_rights_one:
    "You have the following rights in relation to your Personal Information:",
  your_key_rights_two_highlight: "Right to object:",
  your_key_rights_two:
    "You have a right to ask us to consider any valid objections which you have to our use of your Personal Information where we process your Personal Information on the basis of our or a third party's legitimate interest.",
  your_key_rights_three_highlight: "Right of access:",
  your_key_rights_three:
    "All data that you have provided to us is accessible through the Hello Clever Platform. You can request a copy of all Personal Information you have provided us through the Hello Clever Platform or by sending us an email from the email address you use for your Hello Clever account. We can only give you the data we hold ourselves. Any data that a partner holds about you is with that partner. You should request access to that data directly with them. We will help you where we can.",
  your_key_rights_four_highlight: "Right to data portability:",
  your_key_rights_four:
    "You have a right to ask us to provide your Personal Information to a third-party provider of services.",
  your_key_rights_five_highlight: "Right to rectification:",
  your_key_rights_five:
    "You can control your data through the Hello Clever Platform. Where you cannot change this data through the Hello Clever Platform, you have the right to ask us to rectify inaccurate or incomplete Personal Information which we have about you.",
  your_key_rights_six_highlight: "Right to erasure:",
  your_key_rights_six_prefix:
    "You have the right to ask us to erase your Personal Information. You can close your Hello Clever account by emailing us at ",
  your_key_rights_six_suffix:
    ", or by using the appropriate feature in the app, when available. We will then anonymise and archive all data we have about you 12 months later (we keep this data for 12 months in case of any subsequent queries and for audit requirements, unless you ask us to delete it as soon as possible following closure which you can do by sending us an email at",
  your_key_rights_six_suffix_2:
    "). Be aware that the partners you have used may still have data about you. You will need to contact them directly in order for them to delete this data.",

  your_key_rights_seven_highlight:
    "Right to restrict use of your Personal Information: ",
  your_key_rights_seven:
    "You have a right to ask us to restrict the way that we process your personal data in certain specific circumstances. We will also pass your request onto other recipients of your personal data unless that is impossible or involves disproportionate effort.",
  your_key_rights_eight_highlight_prefix:
    "If you wish to exercise any of the rights set out above, please email us at",
  your_key_rights_eight_highlight_suffix:
    "We aim to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.",
  
  where_we_store: "11.	Where We Store Your Personal Information",
  where_we_store_one:
    "All Personal Information collected is stored on secure servers in Australia and other data centres worldwide. By using the Hello Clever Platform, you are consenting to the transfer of your Personal Information to other jurisdictions that may have different privacy laws and regulations than your jurisdiction.",
  where_we_store_two:
    "We retain your Personal Information for as long as you maintain an open account with us of as otherwise necessary to provide you with the Hello Clever Platform. In some cases, we retain Personal Information for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule, regulation, or contract. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.",
  
  protecting_data: "12. Protecting Your Personal Data", 
  protecting_data_one:
    "We take the protection of your Personal Information seriously, however, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee “perfect security”. In addition, any information you send to us may not be secure while in transit. We recommend that you do not use unsecure channels to communicate sensitive or confidential information to us.",
  protecting_data_two:
    "How long we retain your Personal Information depends on different factors, such as whether we need the information to maintain your account, to provide the Hello Clever Platform, comply with legal obligations, resolve disputes, or enforce other applicable contracts and policies.",
  protecting_data_three:
    "To ensure the security and integrity of your data, we have implemented the following measures:",
  protecting_data_three_list_one_highlight: "Encryption:",
  protecting_data_three_list_one:
    "We encrypt Personal Information appropriately and use proper technical and organisational measures across the business. This includes encrypting your data backups to prevent unauthorised access.",
  protecting_data_three_list_two_highlight: "Environment Separation:",
  protecting_data_three_list_two:
    "We maintain strict separation between test and production data environments. This prevents Personal Information from leaking into less secure environments and minimises the risk of exposure.",
  protecting_data_three_list_three_highlight: "Data Loss Prevention:",
  protecting_data_three_list_three:
    "We have implemented a comprehensive data loss prevention strategy. This strategy includes a combination of technical controls, policies, and standards that protect against the possibility of unauthorised extraction or loss of Personal Information.",
  protecting_data_three_list_four_highlight: "Staff Access Limitations:",
  protecting_data_three_list_four:
    "We limit staff access to protected customer data to authorised personnel who require access for legitimate business purposes. Access is granted on a need-to-know basis, minimising the risk of improper access, exfiltration, or processing of Personal Information.",
  protecting_data_three_list_five_highlight: "Strong Passwords:",
  protecting_data_three_list_five:
    "We require all staff accounts to have strong passwords, which often include a minimum length and a mixture of numbers, letters, and special characters. This helps ensure the security of staff accounts and protects against unauthorised access.",
  protecting_data_three_list_six_highlight: "Access Logs:",
  protecting_data_three_list_six:
    "We keep an access log to protected customer data, recording details of any interactions with the data. This allows us to maintain an audit trail of activity related to data access and helps assess the effectiveness of our security controls.",
  protecting_data_three_list_seven_highlight: "Security Incident Response:",
  protecting_data_three_list_seven:
    "We have implemented a security incident response policy to ensure we respond appropriately to security incidents and data breaches. This policy includes incident severity scales, defined roles and responsibilities, escalation paths, evidence collection procedures, and required actions to mitigate and resolve security incidents.",
  protecting_data_four:
    "Additionally, all third-party interactions with the Hello Clever Platform are made through a secure socket layer (“SSL”), which establishes an encrypted link between our web server and your browser. We have written contracts with each of those third-party processors that contain safeguards for your information.",
  protecting_data_five:
    "Please be aware that while we implement these measures to protect your Personal Information, no security measure is completely foolproof. We continuously evaluate and update our security practices to ensure we maintain the highest level of data protection.",
  protecting_data_six:
    "If you have any concerns about the protection of your Personal Information, or would like more information about our data security practices, please contact us using the details provided in this Privacy Policy. We encrypt Personal Information appropriately and use proper technical and organisational measures across the business.",

  international_users: "13. International Users",
  international_users_one:
    "Please note that we may transfer, store, and process your Personal Information outside the country you live in, including the United States. Your Personal Information is also processed by staff and third-party service providers and partners in these countries.",
  international_users_two:
    "If we transfer your personal information out of Europe, we will rely on recognised transfer mechanisms like the European Commission's Standard Contractual Clauses, or any equivalent contracts issued by the relevant competent authority of the UK, as relevant, unless the data transfer is to a country that has been determined to provide an adequate level of protection.",

  our_contact: "14. Our contact details",
  our_contact_one:
    "You may request access to or correction of your Personal Information in our custody or control, by writing to the address below, attention Compliance Officer. Your right to access or correct your Personal Information is subject to applicable legal restrictions. We may take reasonable steps to verify your identity before granting access or making corrections.",
  our_contact_one_1:
    "If you have any questions about this Privacy Policy, or our personal information handling practices generally, please contact us at:",
  our_contact_two: "Hello Clever Pty Ltd",
  our_contact_three: "388 George Street, Sydney NSW 2000, Australia",

  complains:
    "15. Complaints to the Office of the Australian Information Commissioner (OAIC)",
  complains_one:
    "If you think we have breached the Privacy Act, or you wish to make a complaint about the way we have handled your personal information, you can contact us using the details set out below. Please include your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, then we encourage you to contact us to explain why, or you may raise your complaint with the Office of the Australian Information Commissioner (OAIC) using any of the following contact details or the privacy complaint form on the",
  complains_one_highlight: "AIC website",
  complains_two: "Phone: 1300 363 992",
  complains_three: "Email:",
  complains_three_highlight: "enquiries@oaic.gov.au",
  complains_four: "Mail: GPO Box 5218, Sydney NSW 2001",
  complains_five_highlight: "Changes to our Privacy Policy:",
  complains_five:
    "Clever may update or change this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about we handle personal information. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
  complains_six:
    "Your continued use of the Site or Services following the posting of changes to this policy will be deemed your acceptance of those changes.",
  complains_seven: "This document was last updated on March 25, 2024",
};

export default policy;
