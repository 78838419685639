const payto = {
  instant: "Instant",
  one_click: "One-click",
  real_time: "real-time",
  checkout_solution: "checkout solution",
  in_real_time: "Account-to-account payments in",
  instant_secure: "Real-Time Recurring Payments",
  customer_verification: "customer authorisation",
  customer_verification_description_1:
    "Eliminate uncertainty and enhance payment security with PayTo. Customers securely consent to their PayTo agreements directly from within their online or mobile banking app.",
  customer_verification_description_2:
    "With pre-authorisation, real-time verification and instant notifications, PayTo minimises errors, eliminates dishonours, disputes and likelihood of fraud. While your customers enjoy more clarity and control over their payments, and a seamless digital experience.",
  account_to_account: "with Instant Cashback",
  transforming_the_way:
    "With PayTo, businesses can accelerate cashflow and receive funds instantly, by intitiating one-time, ad-hoc or recurring payments directly from their customers' bank accounts.",
  payments_are_here: "payments are here",
  simplify_your_payment:
    "Transform your payment process with PayTo for secure, real-time payments, initiated directly from your customers' bank accounts. With funds settled in seconds, not days, 24/7, PayTo reduces delays, errors and fees associated with traditional payment methods.",
  enhance_your_cash_flow:
    "And with Hello Clever, you can enhance your customer experience by offering instant cashback rewards. Every time your customer pays you, you have the option to offer instant cashback - a clever way to increase customer loyalty and drive real-time payments.",
  payto_fraud: "Reduce\nFraud",
  payto_fraud_description:
    "Customers consent to a PayTo agreement directly from within their banking app. This reduces the likelihood of fraud and eliminates errors.",
  payto_secure: "Securely Stored & Readily Accessible",
  payto_secure_description:
    "PayTo agreements are stored securely in a central database, that can easily be accessed at any time from customers.",
  payto_faq: "No More Manual Reconciliation",
  payto_faq_description:
    "PayTo payments can be immediately reconciled without extra remittance information, making the process smoother, straightforward and accurate.",
  payto_track: "Keep Track\nof Payments",
  payto_track_description:
    "Gain a real-time view of all payment information, including when changes are made to payment agreements.",
  payto_reduce: "Eliminate\ndishonour fees",
  payto_reduce_description:
    "There is no charge to you if a customer has insufficient funds at the time of a payment. Instead, we'll promptly notify you so you can take faster action and avoid delays.",
  recurring_payments: "Recurring payments",
  recurring_payments_description:
    "For fixed or variable recurring payments, such as billers,  subscriptions, loan providers and card-on-file.",
  one_off: "One-off & adhoc payments",
  one_off_description:
    "PayTo is an ideal solution for e-commerce stores and businesses that require online one-off or ad hoc payments.",
  take_payto: "Take PayTo to the next level with",
  boost_customer: "Boost customer retention with instant cashback",
  boost_customer_description:
    "Hello Clever's PayTo solution provides customers with the added incentive of instant cashback rewards. This encourages real-time payment and serves as a powerful retention and loyalty tool that incentivises repeat business, whether it's a one-time purchase or a recurring payment.",
  enjoy: "Enjoy a simple API integration",
  enjoy_description:
    "At Hello Clever, we provide an agile and cost-effective product development and customisation model. This enables us to quickly adapt to the evolving needs of our clients at a competitive price. Our integration process is lightning-fast, ensuring a swift and seamless experience.",
  easy_migration: "An easy migration for existing direct debit  customers",
  easy_migration_description:
    "Switching to PayTo is easy. You can migrate your current direct debit customers without requiring their re-approval, saving you valuable time and resources.  Businesses can streamline their payment process and ensure a smooth transition for their customers.",
  more_efficient: "A more efficient\n",
  alternative: "alternative",
  direct_debit: "to\ndirect debit",
  fast_secure: "Fast and Secure",
  fast_secure_description:
    "Create a PayTo payment agreement with your customer in seconds, compared to the traditional slow, manual process of direct debit.",
  settlement: "Instant settlement",
  settlement_description:
    "With PayTo, enjoy real-time payment processing 24/7, with immediate confirmation of payment outcomes, instead of waiting three to five business days.",
  bill_payments: "Bill Payments",
  subscriptions: "Subscriptions",
  invoices: "Invoices",
  loans: "Loans",
  one_off_purchases: "One-off purchases",
  solution: "solution",
  all_use_cases: "for all use cases",
  payto_is_the: "PayTo is the",
  way_to_do: "\nway to do business",
  ready_to_transform:
    "Ready to transform your payment processing and keep up with the pace of the real-time economy? Discover Hello Clever's PayTo solution by speaking to one of our team members today.",
  hello_clever_one_click_subtitle:
    "Hello Clever's one-click checkout simplifies payments with PayTo, an alternative to credit cards kept on file. Customers can set up a PayTo agreement with you, securely storing their payment information for future purchases. This ensures a faster checkout experience for customers.",
};

export default payto;
