import loadable from "@loadable/component";

import LayoutHeader from "./Header";
import LayoutFooter from "./Footer";

import { Typography } from "./Typography";

import Button from "./Button";
import DrawerContent from "./Header/MobileHeader/DrawerContent";
import AppDownLoadButton from "./AppDownloadButton";
import RotateHighlightText from "./RotateHighlightText";
import ExpandButton from "./ExpandButton";

const WrapList = loadable(() => import("./WrapList"), {
  ssr: false,
});
const HorizontalList = loadable(() => import("./HorizontalList"), {
  ssr: false,
});
const BlogSection = loadable(() => import("./BlogSection"), { ssr: false });
const OfferItem = loadable(() => import("./OfferItem"), { ssr: false });
const LottieImage = loadable(() => import("./LottieImage"), { ssr: false });
const SubscribeEmail = loadable(() => import("./SubscribeEmail"), {
  ssr: false,
});
const VentureSection = loadable(() => import("./VentureSection"), {
  ssr: false,
});
const PaymentsSliderSection = loadable(
  () => import("./PaymentsSliderSection"),
  { ssr: false },
);
const HubspotForm = loadable(() => import("./HubspotForm"), { ssr: false });

export {
  LayoutHeader,
  LayoutFooter,
  Typography,
  Button,
  DrawerContent,
  AppDownLoadButton,
  RotateHighlightText,
  HorizontalList,
  WrapList,
  LottieImage,
  OfferItem,
  SubscribeEmail,
  BlogSection,
  VentureSection,
  ExpandButton,
  PaymentsSliderSection,
  HubspotForm,
};
