import { useState } from "react";

import { classnames } from "Utils/Classnames.Util";
import { BUSINESS_SUBMENUS, BUSINESS_URL } from "Constants/Menu.Constant";

import { Typography } from "../../../Typography";

import MenuItem from "./MenuItem";

const BussinessCollapse = ({
  menu,
  currentPath,
  index,
}: {
  menu: { id: number; label: string; url: string };
  currentPath: string;
  index: number;
}) => {
  const [isExpanding, setIsExpanding] = useState(false);

  const onToggle = () => {
    setIsExpanding(!isExpanding);
  };

  return (
    <div
      tabIndex={index}
      className={classnames("collapse collapse-arrow relative flex flex-col ", {
        "collapse-close": !isExpanding,
      })}
    >
      <input
        type="checkbox"
        className="peer absolute inset-0 max-h-12 cursor-pointer"
        checked={isExpanding}
        onChange={(event) => {
          event.preventDefault();
          onToggle();
        }}
      />
      <li
        suppressHydrationWarning={true}
        onClick={(event) => {
          event.stopPropagation();
          onToggle();
        }}
        className={classnames("collapse-title min-h-0 p-0", {
          "text-clever-link font-medium": BUSINESS_URL.includes(currentPath),
        })}
      >
        <Typography.Content>{menu.label}</Typography.Content>
      </li>
      <div className="collapse-content pb-0 sm:ml-3">
        <ul className="menu my-2">
          <Typography.Description className="menu-title font-semibold text-clever-description">
            {BUSINESS_SUBMENUS.INDUSTRY.title}
          </Typography.Description>
          {BUSINESS_SUBMENUS.INDUSTRY.submenu.map((submenu) => {
            return (
              <MenuItem
                key={submenu.id}
                menu={submenu}
                active={submenu.url === currentPath}
              />
            );
          })}
          <Typography.Description className="menu-title font-semibold text-clever-description">
            {BUSINESS_SUBMENUS.SOLUTION.title}
          </Typography.Description>
          {BUSINESS_SUBMENUS.SOLUTION.submenu.map((submenu) => {
            return (
              <MenuItem
                key={submenu.id}
                menu={submenu}
                active={submenu.url === currentPath}
                target={submenu.target}
              />
            );
          })}
          <Typography.Description className="menu-title font-semibold text-clever-description">
            {BUSINESS_SUBMENUS.LINKS.title}
          </Typography.Description>
          {BUSINESS_SUBMENUS.LINKS.submenu.map((submenu) => {
            return (
              <MenuItem
                key={submenu.id}
                menu={submenu}
                active={submenu.url === currentPath}
                target={submenu.target}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default BussinessCollapse;
