const tyro = {
  tyro: "Tyro",
  in_store_instant: "In-store Instant Cashback Rewards",
  reward: "Reward",
  your_regulars: "\nYour Regulars",
  offer_instant: "Offer Instant Cashback to Customers at Point of Sale",
  in_partnership: "In Partnership\nwith",
  turn_new_customer:
    "Turn every new customer into a loyal regular with Hello Clever Instant Cashback Rewards, now available with all Tyro POS terminals.",
  now_you_keep:
    "Now you can keep your customers coming back for more at both your eCommerce store and your physical locations.",
  clever_cashback_reward:
    "Hello Clever Instant Cashback Rewards is a faster, more rewarding way to retain your existing customers and attract new customers to your business.",
  our_integrated:
    "Our integrated cashback solution with Tyro POS sets a new standard in loyalty rewards, offering customers instant gratification at the point of sale.",
  customers_in_real_time: "customers\nin real-time",
  cashback_reward:
    "Hello Clever Instant Cashback Rewards works online and in-store in near real-time, setting your business apart from traditional loyalty programs. Today's customers demand instant gratification and now you can deliver with a seamless, real-time rewards solution that's irresistible to your customers.",
  instant_cashback: "Instant Cashback",
  made_simple: "Made Simple",
  for_businesses: "for Businesses",
  instant_cashback_text_one:
    "Set the cashback amount you want to offer customers, directly from the campaign manager in the Hello Clever dashboard.",
  instant_cashback_text_two:
    "Customers visit your store and pay using their preferred physical or virtual card.",
  instant_cashback_text_three:
    "Customers simply tap and pay as normal and receive an instant cashback notification on their mobile device.",
  boost: "Boost",
  revenue_with_rewarding: "Revenue\n with a Rewarding Payment Solution",
  increase_sales:
    "Increase sales and average order value by offering an instantly rewarding payment experience for both new and returning customers. Leverage our cashback campaign tools to effectively reach engaged audiences.",
  drive: "Drive",
  repeat_business: "Repeat Business",
  by_offering:
    "By offering Hello Clever Instant Cashback Rewards, your customers will return more frequently and tell their friends too. This not only increases your sales volume but also cements your venue as the preferred destination for locals and visitors alike.",
  be: "Be",
  discovered: "discovered",
  by_new_customers: "by new customers",
  get_your_business:
    "Get your business in front of new customers by simply creating a Instant Cashback Rewards campaign. Your offer will be visible to all Hello Clever App users and they'll even be notified when they're close to your location.",
  why_opt: "Why opt for instant cashback rewards as a",
  loyalty: "loyalty",
  offering: "offering?",
  the_advantages: "the advantages",
  how_it_works: "How it works\nfor your",
  customers: "customers",
  for_customers: "FOR CUSTOMERS ",
  it_work_text_one:
    "Existing app users can discover venues nearby in the app, or scan the QR code in participating venues to get started.",
  it_work_text_two: "Customers link their cards to the Hello Clever app.",
  it_work_text_three:
    "They pay at the POS as usual, and voila! Customers will earn Instant cashback, with real-time notifications.",
  solution_for_your: "Solution for your",
  hospitality: "Hospitality",
  retail: "Retail",
  services: "Services",
  accommodation: "Accommodation",
  industry: "Industry",
  register: "Register",
  integration: "Integration",
  provide_materials: "Provide materials",
  ready_reward: "Ready to reward",
  sign_up_business: "Sign up your business with Hello Clever",
  connect_tyro: "Connect your Tyro devices from your Hello Clever Dashboard",
  we_provide:
    "We’ll provide you with in-store promotional materials such a QR codes, window stickers and drink coasters",
  cashback_your_pos: "Hello Clever Instant Cashback Rewards at your POS",
  easy_set_up: "EASY SET UP",
  get_started: "Get started",
  now: "Now",
  simple: "Simple",
  pricing: "Pricing",
  fee: "0% fee",
  fee_title_one: "Free for the first 3 months",
  fee_title_two: "Ongoing plan post-trial",
  fee_one: "$0",
  fee_two: "$15 per month",
  fee_content_one: "membership fee",
  fee_content_two: "per location",
  percent_fee_content_one: "just fund cashback to the customer",
  percent_fee_content_two: "to fund cashback ",
  included: "Included",
  offer_cashback:
    "Offer cashback rewards  in real-time – No delays, unlike other programs that take weeks.",
  seamless_integration:
    "Seamless Integration with your Tyro point-of-sale machine.",
  gain_access:
    "Gain access to a dedicated support manager for personalised assistance.",
  encourage_customer:
    "Encourage customer loyalty and stand out against competitors.",
  onboarding_session: "Onboarding session at your venue.",
  track_valuable:
    "Track valuable data and insights, managing campaigns effortlessly from your own dashboard.",
  no_manual: "No manual work or complicated technical set up required.",
  enjoy_fee:
    "Enjoy free partnership marketing opportunities to boost your venue's visibility.",
  your_instant_cashback:
    "Hello Clever: Your Instant Cashback Rewards Partner for Hospitality and Retail ",
  partnership: " in partnership with",
  join_our_program: "Join our launch program and enjoy",
  three_months_access: "3 months access at no cost.",
  want_to_start:
    "Want to start winning and rewarding your customers? Join our Early Launch Program and enjoy ",
  better_faster: "A better, faster loyalty  experience",
  seamless: "Seamless POS integration",
  boost_customer: "Boost customer loyalty",
  low_cost: "Low cost",
  generate_revenue: "Generate New revenue",
  enhanced_campaign: "Enhanced campaign management",
  customer_earn_cashback:
    "Customers earn cashback in real-time, not weeks later. Once they join on the app, they simply tap and pay at your POS with their bank card – no extra steps are required to receive rewards.",
  no_clunky:
    "No clunky technical setups or process changes as its all built-in to your Tyro POS. Get up and running quickly and effortlessly. ",
  offering_instant:
    "Offering instant rewards provides immediate gratification and a memorable experience for customers, ensuring their return to your venue.",
  first_three_month:
    "The first three months are on us!Then enjoy simple pricing, with a low-cost membership plan and low cashback funding fee.",
  drive_repeat:
    "Drive repeat visits and attract new customers, boosting your revenue stream over time.",
  promotional_materials:
    "We’ll povide in-store promotional materials such a QR codes, window stickers and drink coasters.",
};

export default tyro;
