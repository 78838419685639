const home = {
  shop_with_brands: "Shop with brands",
  you_love: "you love, earn",
  the_first_australian:
    "The first Australian buy-to-earn platform that makes shopping, payments, and money management easier for all. Trusted by 100,000+ Australians and {{amount}}+ merchants. Join us now!",
  are_you_merchant: "Are you a merchant? Discover our solution here",
  now_not_later: "Now, not later!",
  a_frictionless_checkout:
    "A frictionless checkout experience that earns you instant rewards from your favourite brands.",
  want_to_see_more: "Want to see more? We have",
  offers_in_our_app: "offers in our app.",
  you_spend: "you spend",
  withdraw_or_save_for_later:
    "From withdrawing to paying bills or saving for your goals, there are so many things you can do with your instant cashback!",
  no_more_jaw_dropping:
    "Easy budgeting: goes and sets a cap. Get notified in real-time when you exceed your limit. No more jaw-dropping surprises!",
  and_get_paid: "and get paid",
  just_one_click_away:
    "Pay mates, share a QR Code to get paid, send an invoice to collect payments for your business, just 1-click away.",
  refer_friends_to: "Refer friends to",
  what_people_say: "What’s new",
  backed_by_global: "Backed by global",
  venture_capital: "venture capital  ",
  the_more_the_merrier:
    "The more, the merrier. Earn {{amount}} for every friend who places their first order in the Hello Clever app. Your friends will earn {{amount}} too.",
  t_and_c_apply: "Terms & Conditions apply.",
  create_account: "Create an account in less than 2 minutes! 🚀 ",
  join_us_now: "Join us now",
};

export default home;
