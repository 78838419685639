import { useState, useEffect } from "react";

import { _get } from "Utils/Lodash.Util";

import LoadFailedImage from "Assets/Images/Others/image_failed_default.png";

const NormalImage: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement> & {
    loadFailedImageUrl?: any;
  }
> = ({ loadFailedImageUrl = LoadFailedImage.src, src, alt = "", ...rest }) => {
  const [state, setState] = useState(src);

  useEffect(() => {
    if (!state || src !== state) setState(src);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const onLoadError = () => {
    setState(loadFailedImageUrl);
  };

  return <img src={state || src} onError={onLoadError} alt={alt} {...rest} />;
};

export default NormalImage;
