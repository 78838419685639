const status = {
  hello_clever: "Hello Clever",
  service: "service",
  status: "status",
  all_service_online: "All services are online",
  uptime: "Uptime",
  downtime: "Downtime",
  percent_uptime_for_90_days: "{{percent}} uptime for the last 90 days",
};

export default status;
