import { BLOGS_CONTANT, CASE_STUDY_TAG } from "Constants/Common.Constant";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { PLATFORM } from "Constants/Platform.Constant";
import { EMAIL_REGEX } from "Constants/Regex.Constant";
import { LOTTIE_URLS } from "Constants/URL.Constant";

import { localesText } from "./LocalesText.Util";
import { _fromPairs, _get, _isString } from "./Lodash.Util";

export const emailChecker = (email: string) => {
  return EMAIL_REGEX.test(email.toLowerCase());
};

export const removeSpace = (value: string) => {
  return (value || "").replace(/\s/g, "");
};

export const ellipsisText = (text: string, limit = 10) => {
  if (text && text.length > limit) {
    return `${text.substring(0, limit)}...`;
  } else {
    return text;
  }
};

export const getShortName = (name: string) => {
  if (!name?.length) return "";

  const _nameArr = name.split(" ");
  const length = _nameArr.length;
  const shortName =
    length === 1 ? _nameArr[0][0] : _nameArr[0][0] + _nameArr[length - 1][0];

  return shortName;
};

export const checkOS = () => {
  if (
    typeof window !== "undefined" &&
    typeof window.navigator !== "undefined"
  ) {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = PLATFORM.WINDOWS;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = PLATFORM.MAC_OS;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = PLATFORM.IOS;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = PLATFORM.WINDOWS;
    } else if (/Android/.test(userAgent)) {
      os = PLATFORM.ANDROID;
    } else if (!os && /Linux/.test(platform)) {
      os = PLATFORM.LINUX;
    }
    return os;
  }

  return PLATFORM.WINDOWS;
};

export const getCashback = ({
  cashback,
  up_to,
  commission_type,
}: {
  cashback: string | number;
  up_to: boolean;
  commission_type: string | null;
}) => {
  const commissionType =
    commission_type && commission_type !== "null" ? commission_type : "%";

  const upTo = !!up_to ? localesText("common.up_to") : "";

  if (commissionType === "$") {
    return upTo + commissionType + cashback;
  }

  return upTo + cashback + commissionType;
};

export const generatePath = (url: string, params?: any) => {
  if (typeof params !== "object") return url;

  const keys = Object.keys(params);
  if (keys.length) {
    keys.forEach((key) => {
      const replaceString = `:${key}`;
      const value = params[key];
      url = url.replace(replaceString, value);
    });

    return url;
  } else {
    return url;
  }
};

export const getBlogSlug = (link: string) => {
  // const domain = "https://hello-clever.medium.com/";
  const domain = "https://woo.cleverpay.store/";
  const tailString = "?source=rss-24b7725a86c6------2";

  const slug = link?.replace(domain, "").replace(tailString, "");

  return slug;
};

export const isCaseStudy = (data: any) => {
  return !!data?.[BLOGS_CONTANT.EMBEDDED]?.[BLOGS_CONTANT.TERM]?.[0].find(
    (item: any) => item.name === CASE_STUDY_TAG,
  );
  // return data?.categories?.includes(CASE_STUDY_TAG);
};

export const generateUrlBlogAndCaseStudySlug = (data?: any) => {
  const option = isCaseStudy(data)
    ? {
        url: PATHS_NAME.CASE_STUDY_DETAIL,
        pathName: "case_slug",
      }
    : {
        url: PATHS_NAME.BLOG_DETAIL,
        pathName: "blog_slug",
      };

  const path = generatePath(option.url, {
    [option.pathName]: getBlogSlug(data.link),
  });

  return path;
};

export const generateJsonServices = (names: string[]) => {
  const urls: any[] = Object.keys(LOTTIE_URLS)
    .map((key: string) =>
      names.includes(key) ? [key, LOTTIE_URLS[key]] : null,
    )
    .filter((ele) => !!ele);

  const formattedUrls = _fromPairs(urls);

  return {
    services: Object.values(formattedUrls).map((url: any) => fetchJson(url)),
    formattedUrls,
  };
};

const fetchJson = (url: string) =>
  fetch(url)
    .then((data) => data.json())
    .then((json: any) => json);

export const getBlogImageSize = (blog: any) => {
  const mediaData = blog?.[BLOGS_CONTANT.EMBEDDED]?.[BLOGS_CONTANT.MEDIA]?.[0];

  const original = _get(mediaData, "source_url", "") || "";
  const thumbnail =
    _get(mediaData, "media_details.sizes.thumbnail.source_url", "") || "";
  const medium =
    _get(mediaData, "media_details.sizes.medium.source_url", "") || "";
  const mediumLarge =
    _get(mediaData, "media_details.sizes.medium_large.source_url", "") || "";
  const woocommerceSingle =
    _get(mediaData, "media_details.sizes.woocommerce_single.source_url", "") ||
    "";
  const woocommerceThumbnail =
    _get(
      mediaData,
      "media_details.sizes.woocommerce_thumbnail.source_url",
      "",
    ) || "";

  return {
    thumbnail,
    medium: mediumLarge || medium || woocommerceSingle || woocommerceThumbnail,
    original,
  };
};

export const getBlogDescription = (blog: any) => {
  const content = _get(blog, "content.rendered", "") || "";

  if (!_isString(content)) return "";

  const cleanText = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
  return cleanText.slice(0, 200).trim() + "...";
};
