import React from "react";
import Link, { LinkProps } from "next/link";

interface INextLink extends LinkProps {
  children?: React.ReactNode;
}

const NextLink = ({
  prefetch = false,
  ...rest
}: INextLink & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <Link prefetch={prefetch} {...rest} />;
};

export default NextLink;
