const buy_to_earn = {
  get_in: "Get in",
  touch: "touch",
  for_customers: "For customers",
  for_business: "For business",
  real_time_payment: "Real-time Payments Solutions that",
  rewards: "Rewards",
  more: "More",
  customers: "customers",
  more_sales: "more sales",
  boost_your_sales:
    "Boost your sales, improve your cashflow, prevent fraud, and reduce chargebacks. Trusted by more than {{amount}} merchants.",
  from_fashion:
    "From fashion and beauty to kitchenware and groceries, no matter what you sell, we’re here to help you collect payments securely in real-time.",
  we_work_with: "We work with",
  all: "all",
  verticals: "verticals",
  easily_integrate:
    "Easily integrate your e-commerce marketplace and install the Hello Clever app or API via our straightforward and understandable API documentation.",
  document_api: "View our API documentation",
  buy_to_earn_tab: "Buy-To-Earn",
  buy_to_earn_tab_content:
    "The ecosystem rewards customers as they shop, cashback can be used as credit increasing the likelihood of a repeat purchase.",
  payid_tab_content:
    "Making and receiving payments is quicker and easier than ever using PayID. Your PayID is a unique identifier linked to your transaction account. All you need is an email address, mobile number or ABN to send and receive funds almost instantly.",
  payto_tab_content:
    "Take advantage of the New Payment Platform that pre-authorises transactions to help merchants receive their payments almost instantly. PayTo can be used for in-app and eCommerce transactions, including one-off, ad-hoc, or account on file payments.",
  card_tab_content:
    "Faciliate card payments from all major card issuers. Aimed to reduce fraud and provide added security to online payments with 3D Secure 2 (3DS2) you can provide frictionless authentication and improve the purchase experience.",
  or_tailor_your: "Or tailor your integration using",
  hello_clever_api: "Hello Clever API",
  take_advantage:
    "Take advantage of the New Payments Platform (NPP) to receive payments faster, more securely and at a lower cost.",
  reduce_chargebacks: "Reduce Chargebacks with PayID",
  reduce_chargebacks_description:
    "Hello Clever’s highly secure PayID registration and orchestration process reduces fraud and chargebacks.",
  immediate_reconciliation: "Immediate Reconciliation",
  immediate_reconciliation_description:
    "All PayID and PayTo transactions are validated instantly, making reconciliation simple and easy for your business.",
  real_time_settlement: "Real-time Settlement",
  real_time_settlement_description:
    "Offer Australian built PayID & PayTo for secure, real-time payment settlement 24/7 365 days a year.",
  instant_customer: "Instant customer gratification",
  instant_customer_description:
    "Simply set up cashback campaigns, reward customers in real-time and increase loyalty. ",
  reach_new_customers: "Reach new customers",
  reach_new_customers_description:
    " Get featured in Hello Clever app and recommended to 100,000+ Australians.",
  marketing_assets: "Marketing assets on demand",
  marketing_assets_description:
    "Our amazing designers will create campaign assets for your cashback program!",
  increase_basket: "Increase basket size",
  increase_basket_description:
    "Bigger basket size means more cashback, who doesn’t love bigger rewards!",
  reduce_cart: "Reduce cart abandonment rate",
  reduce_cart_description:
    "Incentivise your customers with a one-click express checkout experience paired with a cashback offering.",
  do_you_have: "Do you have a",
  can_do_much_more:
    "Easily white label Hello Clever real-time payment and buy-to-earn solutions and build for your own needs with Hello Clever API",
  how_long: "How long does it take to integrate with Hello Clever?",
  payment_options: "What payment options are available?",
  which_countries: "Which countries does this payment gateway support?",
};

export default buy_to_earn;
