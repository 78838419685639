import { NextLink, NextImage } from "Components/Lib/Next";
import { Button } from "Components/Own";
import { PATHS_NAME } from "Constants/PathName.Constant";
import { localesText } from "Utils/LocalesText.Util";

import classes from "./index.module.scss";

import internalBg from "Assets/Images/Error/background-500.png";
import internalIcon from "Assets/Images/Error/500-error.png";

const InternalServer: React.FC = () => {
  return (
    <div id="404" className={classes["not_found-container"]}>
      <div className="relative flex h-full w-full items-center justify-center">
        <div className={classes["background-500-container"]}>
          <div className={classes["background-500"]}>
            <NextImage
              src={internalBg}
              width={1300}
              height={640}
              alt="500-background"
            />
          </div>
          <div
            className={`flex flex-col items-center justify-center gap-6 lg:absolute  ${classes["title-container"]}`}
          >
            <NextImage src={internalIcon} width={270} height={140} alt="500" />
            <h1 className={classes["error-500-title"]}>
              {localesText("errors.oops_something_went_wrong")}
            </h1>
            <p className={classes["error-500-subtitle"]}>
              {localesText("errors.try_to_refresh")}
            </p>
            <NextLink href={PATHS_NAME.HOME}>
              <Button className="rounded-full" buttonSize="large">
                {localesText("common.return_to_homepage")}
              </Button>
            </NextLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalServer;
