import { createContext, useContext } from "react";

import { THEME_ENUMB } from "Types";
import { PAGE_THEME } from "Constants/Common.Constant";

export interface ThemeContextType {
  theme: THEME_ENUMB;
}

const ThemeContext = createContext<ThemeContextType | null>(null);

// Provide Context
export const ThemeProvider = ({
  children,
  initValue,
}: {
  children: any;
  initValue?: any;
}) => {
  return (
    <ThemeContext.Provider value={{ theme: PAGE_THEME.PRIMARY, ...initValue }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () =>
  useContext(ThemeContext) as ThemeContextType;

export default ThemeProvider;
