const in_store = {
  in_store: "in-store",
  earn_instant_cashback: "Earn instant cashback with just a tap",
  supercharge_savings:
    "Supercharge your savings with in-store instant cashback rewards. Simply use your everyday bank card at connected stores near you, and enjoy cashback faster than any other rewards program—no waiting weeks, get it instantly. Clever, right?",

  //section2
  rewards_in_store: "\nRewards in-store is here",
  real_cash: "Get real cash - instantly",
  pointless_points: "No more pointless points",
  tap_and_pay: "Just tap and pay as usual",
  say_goodbye:
    "Say goodbye to waiting weeks to redeem your cashback rewards. With Hello Clever, your cash will be landing in your wallet in real-time.",
  skip_frustration:
    "Skip the frustration of accumulating unused  points, and enjoy real cash rewards that you'll actually love.",
  simply_tap:
    "Simply tap your bank card as usual and enjoy instant in-store cashback without any extra steps or hassle. It's that easy!",
  //section3
  spend_in_store: "as you\nspend in-store",
  link_bank_card:
    "Link your everyday bank card to the Hello Clever app and start earning instant cashback at our partnered cafes, restaurants, and stores!",
  forget_waiting:
    "Forget waiting weeks to cash in on your rewards – just tap and pay with your bank card as you usually would, and watch your cashback earnings land in your hello Clever wallet instantly!",
  //section4
  link_your_cards: "Link your cards",
  explore_stores: "Explore nearby stores",
  pay_and_earn: "Pay and earn",
  download_app:
    "Download the Hello Clever app and link your preferred debit or credit card – the one you use most for your purchases.",
  discover_nearby:
    "Discover nearby stores offering instant cashback deals through our app's interactive map feature.",
  pay_with:
    "Pay with your linked bank card, whether it's the physical card or in your mobile wallet, just as you normally would.",

  //section5
  tap_and_get: "Tap and get\n",
  that_easy: "\nit’s that easy",
  once_your_card:
    "Once your card is linked to Hello Clever, every time you use your physical or virtual card in your mobile wallet for in-store purchases, you'll instantly earn cashback.",
  no_complicated:
    "No complicated extra steps, no scanning QR codes, and no copying links. Say hello to clever in-store shopping!",

  //section6
  enjoy: "Enjoy",
  your_way: "\nyour way",
  stay_on_top:
    "Stay on top of the latest instant cashback offers and irresistible deals in our app.",
  track_your_earnings:
    "Track your earnings growth in your Hello Clever wallet.",
  cash_out_your_rewards:
    "Cash out your rewards to your bank account or use it to put towards future purchases.",

  //section7
  find: "Find",
  cashback_deals: "cashback\ndeals nearby",
  use_the_map:
    "Use the map feature in the app to discover nearby cafes, restaurants and retailers offering instant cashback deals.",

  //section8
  clever_today: "Clever today",
  download_the_app: "Download the app to start shopping with Hello Clever now.",
  hear_from_our: "Hear from our\n",
  clever_customers: "Clever customers",
  hundreds_of_aussies:
    "Don’t just take it from us. Hundreds of Aussies are saving more and enjoying a rewarding shopping experience",
  //section9
  the_instant_cashback:
    "The instant cashback feature is a game-changer! No more waiting for weeks to see savings. As soon as I made my purchase, the cashback was credited to my account, providing that instant gratification we all love.",
  recently_discovered:
    "I recently discovered a fantastic way to get instant cashback, and I couldn't be happier with the results! Hello Clever has truly transformed my shopping experience.",
  the_user_interface:
    "The user interface is intuitive and user-friendly. Navigating through the platform is a breeze, and the cashback process is straightforward.",
  customer_name_one: "Kayla",
  customer_name_two: "Adam",
  customer_name_three: "James",
  customer_address_one: "28, Sydney",
  customer_address_two: "32, Melbourne",
  customer_address_three: "27, Brisbane",
  how: "How",
  it_works: " it works",
};

export default in_store;
