const contact = {
  form_title: "Do you have any questions?",
  form_subtitle:
    "Let us know any questions you may have, we are happy to answer.",
  form_field_name: "Your Name *",
  form_field_first_name: "First name *",
  form_field_last_name: "Last name *",
  form_field_company: "Company *",
  form_field_work_email: "Work email *",
  form_field_email: "Email Address *",
  form_field_request: "Please tell us more about your request",
  form_field_message: "Type your message",
};

export default contact;
