import { memo } from "react";
import { classnames } from "Utils/Classnames.Util";

export interface IExpandButton {
  containerProps?: React.HTMLProps<HTMLLabelElement>;
  inputProps?: React.HTMLProps<HTMLInputElement>;
}

const ExpandButton = ({
  containerProps = {},
  inputProps = {},
}: IExpandButton) => {
  const { className: containerClassName, ...containerRest } = containerProps;

  return (
    <label
      className={classnames(
        "swap-rotate swap h-[40px] w-[40px] items-center justify-center rounded-full border-none bg-white",
        containerClassName
      )}
      {...containerRest}
    >
      <input className="h-[auto] w-[auto]" type="checkbox" {...inputProps} />

      <div className="swap-off h-[10px] w-[10px] bg-[url('/Assets/Svg/plus-icon.svg')] bg-contain" />
      <div className="swap-on flex h-[10px] w-[10px] items-center justify-center">
        <div className="h-[3px] w-[10px] bg-[url('/Assets/Svg/minus-icon.svg')] bg-contain" />
      </div>
    </label>
  );
};

export default memo(ExpandButton);
