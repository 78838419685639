export const formatCurrency = (n: number, currency?: string) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: 0,
  }).format(n);
};

export const formatNumber = (value: string | number) => {
  const number = `${value}`.replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d*)\d*$/g,
    function (m, s1, s2) {
      return s2 || `${s1},`;
    }
  );
  const e = number === "undefined" ? "" : number;
  return e;
};

export const formatToNumber = (number: any) => {
  const parseToNumber = Number(number);
  if (isNaN(parseToNumber)) return 0;

  return parseToNumber;
};
